import { setupIonicReact } from '@ionic/react';
import { createOvermind } from 'overmind';
import { Provider } from 'overmind-react';
import React from 'react';
import ReactDOM from 'react-dom';
import { App } from './App';
import { config } from './overmind';
import reportWebVitals from './reportWebVitals';
import * as serviceWorkerRegistration from './serviceWorkerRegistration';

const overmind = createOvermind(config, {
  devtools: true,
  // devEnv: "production"
})

setupIonicReact({
  mode: 'md'
})

// istanbul ignore else
if (window.Cypress) {
  window.overmind = overmind
}

declare global {
  interface Window { overmind: typeof overmind, Cypress: any }
}

ReactDOM.render(
  <React.StrictMode>
    <Provider value={overmind}>
      <App />
    </Provider>
  </React.StrictMode>,
  document.getElementById('root')
);

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://cra.link/PWA
serviceWorkerRegistration.unregister();

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
